<script setup lang="ts">
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'

defineProps({
  tabsGroup: {
    type: Array as PropType<any[]>,
    required: true,
  },
})

const scrolltabs: Ref<HTMLDivElement | null> = ref(null)
const currentTabIndex = ref(0)
const tabClickScroll = (i: number) => {
  if (!scrolltabs.value)
    return
  if (i > currentTabIndex.value) {
    // scroll smooth 100px to the right
    scrolltabs.value.scrollBy({
      left: 110,
      behavior: 'smooth',
    })
  }
  else {
    // scroll smooth 100px to the left
    scrolltabs.value.scrollBy({
      left: -110,
      behavior: 'smooth',
    })
  }

  currentTabIndex.value = i
}
</script>

<template>
  <div class="">
    <TabGroup id="tabs" as="div" class="">
      <div ref="scrolltabs" class="-mx-4 flex overflow-x-auto sm:mx-0 container-8xl no-scrollbar">
        <div class="flex-auto border-b border-black/5 px-4 sm:px-0">
          <TabList id="tablist" class="-mb-px flex space-x-10">
            <Tab
              v-for="(tabGroup, i) in tabsGroup"
              :id="`tab-${tabGroup._key}`"
              :key="tabGroup._key"
              v-slot="{ selected }"
              as="template"
            >
              <button
                :id="`tab-button-${tabGroup._key}`"
                class=" whitespace-nowrap border-b-2 py-6 text-base font-normal focus:outline-none z-10"
                :class="[!selected ? 'border-transparent text-gray-700 hover:border-forest-300 hover:text-forest-600' : 'border-forest-500 text-forest-600']"
                @click="tabClickScroll(i)"
              >
                {{ tabGroup.tabTitle }}
              </button>
            </Tab>
          </TabList>
        </div>
      </div>

      <TabPanels as="template">
        <TabPanel
          v-for="(tabGroup) in tabsGroup"
          :id="tabGroup._key"
          :key="tabGroup._key"
          class="py-6"
        >
          <FeaturesTabsCards
            v-if="tabGroup.tabs?.tabs"
            :tabs="tabGroup.tabs.tabs"
          />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>
