<script setup lang="ts">
const { urlFor } = useSanityImage()
const query = groq`*[_type == "home"]{
  heading,
  eyebrow,
  subtitle,
  body,
  tabs,
}[0]`
const sanity = useSanity()
const { data, error } = await useAsyncData('home', () => sanity.fetch<Sanity.Default.Schema.Home>(query))
// // console.log(data.value)

if (error.value) {
  console.error(error)
  throw createError({ status: 404, message: 'Page Not Found' })
}

useSchemaOrg([
  defineOrganization({
    name: 'Butler Sherborn',
    logo: 'https://www.butlersherborn.co.uk/logo/butler-sherborn.png',
    sameAs: [
      'https://twitter.com/ButlerSherborn',
      'https://en-gb.facebook.com/butlersherborn/',
      'https://www.linkedin.com/company/butler-sherborn',
      'https://www.instagram.com/butlersherborn/',
    ],
    url: 'https://www.butlersherborn.co.uk/',
    description: data.value?.subtitle,
  }),
  defineWebPage({
    name: 'Butler Sherborn | Cotswolds Property',
    url: 'https://www.butlersherborn.co.uk/',
    description: data.value?.subtitle,
  }),
])

onMounted(() => {
  // console.log('Designed and developed by Danny Johnson - http://mrdannyjohnson.co.uk')
})
</script>

<template>
  <div v-if="data" class="pb-12">
    <Seo
      :title="data.heading"
      :description="data.subtitle"
      :image-url="data.image ? urlFor(data.image.asset._ref).width(800).url() : ''"
    />
    <HeaderHeroVideo
      :headline="data.heading"
      :subtitle="data.subtitle"
      :eyebrow="data.eyebrow"
    >
      <UiFader class="text-2xl font-semibold text-gray-100">
        <SearchForm />
      </UiFader>
    </HeaderHeroVideo>

    <div class="container-8xl mt-12 mb-8">
      <div class="wrap max-w-2xl">
        <h3 class="text-xl font-bold mb-4">
          {{ data.heading }}
        </h3>
        <SanityContentWrapper
          class="text-sm"
          :blocks="data.body"
        />
      </div>
    </div>

    <CarouselsExpensiveProperties class="mb-8" />

    <CarouselsSuccess class="mb-8" />

    <FeaturesTabsMain
      v-if="data.tabs && data.tabs.length"
      class="mb-16"
      :tabs-group="data.tabs as any"
    />

    <FeaturesFullImage
      slug="equestrian-property"
      link-text="Equestrian Property"
      href="/rural/equestrian-property"
      class="mb-16"
    />

    <ClientOnly>
      <LazyCarouselsFavourites />
      <LazyCarouselsRecentlyViewed class="mb-12" />
    </ClientOnly>

    <div class="container-8xl">
      <CtaImage
        v-if="data"
        slug="free-market-appraisal"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
