<script setup lang="ts">
const props = defineProps({
  slug: {
    type: String,
    required: true,
  },
  linkText: {
    type: String,
    default: 'Market Appraisal',
  },
  href: {
    type: String,
    default: '/property-services/sell',
  },
})

const fullImageQuery = groq`*[slug.current == "${props.slug}"]{
  heading,
  subtitle,
  image{
    ...,
    "asset": {
      "_ref": asset._ref,
      "metadata": {
        "lqip": asset->metadata.lqip,
      }
    }
  },
  slug,
} | order(date desc)[0]`

const sanity = useSanity()
const { data: fullImage, error } = await useAsyncData(fullImageQuery, () => sanity.fetch<any>(fullImageQuery))
// // console.log(fullImage.value)

if (error.value) {
  // console.log('Error fetching success stories')
  console.error(error)
}

const { urlFor } = useSanityImage()
</script>

<template>
  <div
    v-if="fullImage && fullImage.image"
    class="full-image relative h-[650px] xl:h-[750px] isolate w-full overflow-hidden"
  >
    <nuxt-img
      :src="urlFor(fullImage.image).width(1600).height(650).fit('crop').url()"
      :alt="fullImage.alt ?? 'Call to action background image of Cotswolds property'"
      quality="85"
      class="h-[650px] xl:h-[750px] object-cover object-center w-full block sticky top-0 left-0"
      data-rellax-speed="-4"
      data-rellax-percentage="0.67"
      sizes="sm:100vw md:100vw lg:100vw xl:100vw"
      :width="1600"
      :height="650"
      loading="lazy"
      :placeholder="fullImage.image.asset?.metadata?.lqip"
    />
    <div class="absolute opacity-30 bg-black inset-0" />
    <div class="absolute bg-gradient-to-t from-black via-transparent to-transparent inset-0" />

    <div class="absolute container-8xl inset-0 py-8 px-4 2xl:px-20 h-[650px] xl:h-[750px] flex items-end text-white">
      <div class="max-w-3xl hero-image justify-center text-center mx-auto">
        <div v-if="fullImage.eyebrow" class="eyebrow">
          {{ fullImage.eyebrow }}
        </div>
        <h2 class="text-sm lg:text-xl text-gray-50 font-normal">
          {{ fullImage.subtitle }}
        </h2>
        <UiBtn
          size="md"
          color="forest"
          variant="solid"
          :to="props.href"
        >
          {{ linkText }}
        </UiBtn>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
