<script setup lang="ts">
declare interface SanityTabCard {
  _key: string
  title: string
  body: string
  link: string
  href: string
  image?: {
    alt: string
    asset: {
      _type: string
      _ref: string
    }
  }
}

defineProps({
  tabs: {
    type: Object as PropType<SanityTabCard[]>,
    required: true,
  },
})

const { urlFor } = useSanityImage()
</script>

<template>
  <div v-if="tabs">
    <!-- <div class="grid grid-cols-1 md:grid-cols-3 gap-12 xl:gap-16"> -->
    <div
      class="px-4 lg:px-0 carousel flex flex-nowrap gap-4 lg:gap-6 overflow-x-scroll no-scrollbar snap-x snap-mandatory scroll-px-4"
    >
      <div
        v-for="(service) in tabs"
        :key="service._key"
        class="snap-start lg:last:pr-12 magic-padding shrink-0 w-5/6 sm:w-auto"
      >
        <div class="w-full sm:max-w-[400px]">
          <nuxt-img
            v-if="service?.image"
            :src="urlFor(service.image.asset._ref).width(400).height(175).fit('crop').crop('focalpoint').url()"
            :alt="service?.image.alt"
            quality="80"
            :width="400"
            :height="175"
            class="object-cover object-center aspect-[16/7] mb-4 w-full bg-gradient-to-tr from-gray-400 to-gray-200"
            loading="lazy"
          />
          <h4 class="text-base font-semibold text-gray-700 mb-2">
            {{ service?.title }}
          </h4>
          <p class="font-normal text-gray-700 mb-2 text-sm">
            {{ service?.body }}
          </p>
          <UiBtn
            v-if="service?.href && service?.link"
            variant="link"
            color="forest"
            size="xs"
            :to="service?.href"
            :label="service?.link"
            :padded="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>
